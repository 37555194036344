import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, SEO } from '@global'
import { Hero, FiftyFifty, Blockquote, CTA, SocialProof } from '@layouts'
import { Process } from '@layouts/Custom/Process/Process'

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "services/Hero_Services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgOne: file(relativePath: { eq: "services/1_Services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quote: file(relativePath: { eq: "services/Quote_Services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="relative">
      <SEO title="Services" />
      <Layout>
        <Hero
          fluid={data.hero.childImageSharp.fluid}
          eyebrow="our services"
          title="Your bathroom is ready for an update"
        />
        <FiftyFifty
          flip
          fluid={data.imgOne.childImageSharp.fluid}
          eyebrow="full service"
          heading="what we do"
          className="py-8 sm:pt-12 lg:py-24"
          to="/gallery/"
          ctaText="View the Gallery"
        >
          <ul className="list-disc list-inside">
            <li className="mt-1">Complete bathroom remodeling</li>
            <li className="mt-1">Bathtub replacement</li>
            <li className="mt-1">Shower replacement</li>
            <li className="mt-1">Bathtub to walk in shower</li>
            <li className="mt-1">Vanities, floors</li>
            <li className="mt-1">Sink tops, faucets, toilets, vent fans</li>
            <li className="mt-1">Tile, solid surface materials</li>
          </ul>
        </FiftyFifty>
        <Process />
        <Blockquote
          fluid={data.quote.childImageSharp.fluid}
          className="pb-20 py-16 lg:py-40"
        >
          I <span className="text-blue-200">could not be more pleased</span>{' '}
          with the end result.{' '}
          <span className="text-blue-200">From the original contact</span> with
          the owner Dan Updike and his original sales call, to the selection of
          sides and base,{' '}
          <span className="text-blue-200">
            to the actual installation, everything went flawlessly.
          </span>
        </Blockquote>
        <CTA
          className="py-24 text-center bg-gray-100"
          heading="Need to talk through your project?"
          to="/contact/"
          ctaText="Contact Us"
        />
        <SocialProof />
      </Layout>
    </div>
  )
}
export default ServicesPage
