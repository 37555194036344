import React from 'react'

export const Step = ({ src, alt, title, description, number }) => {
  return (
    <figure className="">
      <div className=" icon-box rounded overflow-hidden border-4 border-blue-200 flex items-center justify-center">
        <img src={src} alt={alt} />
      </div>
      <figcaption>
        <h4 className="mt-5 text-3xl text-blue-400 font-bold leading-none">
          {number}. {title}
        </h4>
        <p className="mt-4 text-sm  xl:text-base text-gray-400">
          {description}
        </p>
      </figcaption>
    </figure>
  )
}
