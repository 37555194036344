export const processData = [
  {
    number: 1,
    title: 'Conversation ',
    description:
      "Let’s talk! We’ll ask what you’re wanting to do and be upfront if we're the right fit for your project. Our conversation will finish with a rough ballpark price for the scope of work you’re looking to have done.",
  },
  {
    number: 2,
    title: 'Consultation',
    description:
      'Together, we’ll set up an appointment for our owner to come take measurements, make drawings, and explain more about how the rest of the process takes place.',
  },
  {
    number: 3,
    title: 'Details',
    description:
      'After the appointment, we will put together a detailed quote and description of the job and send it to you for review.',
  },
  {
    number: 4,
    title: 'Selections',
    description:
      'Time for the fun part. You’ll come to our showroom or be directed to tile distributors to choose materials in order to bring your vision to life. We’ll help coordinate decisions to make sure you’re completely satisfied and confident with your choices.',
  },
  {
    number: 5,
    title: 'Contract',
    description:
      'Once all materials have been chosen, we will put together a contract for you to sign. This eliminates any confusion or surprise hidden costs associated with the materials you choose. ',
  },
  {
    number: 6,
    title: 'Schedule',
    description:
      'After the contract has been signed, all of your materials will be ordered. Your job will be scheduled once everything has come in to ensure that there are no delays during the remodeling process. ',
  },
]
