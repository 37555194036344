import React from 'react'
import { Step } from './Step'
import { Introduction } from './Introduction'
import { processData } from './data/process'
import Step1 from '@icons/step-1.svg'
import Step2 from '@icons/step-2.svg'
import Step3 from '@icons/step-3.svg'
import Step4 from '@icons/step-4.svg'
import Step5 from '@icons/step-5.svg'
import Step6 from '@icons/step-6.svg'

export const Process = ({ className = 'py-10' }) => {
  const icons = [Step1, Step2, Step3, Step4, Step5, Step6]
  return (
    <section className={className}>
      <div className="container">
        <Introduction />
        <div className="mt-8 md:mt-10 lg:mt-16 grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10 lg:gap-16 xl:gap-32 max-w-lg md:max-w-none mx-auto">
          {processData.map((step, idx) => (
            <Step
              key={idx}
              src={icons[step.number - 1]}
              number={step.number}
              name={step.name}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
