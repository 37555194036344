import React from 'react'

export const Introduction = () => {
  return (
    <div className="text-center max-w-xl mx-auto ">
      <h2 className="text-4xl lg:text-5xl text-blue-400 font-bold">
        Our Transparent Process
      </h2>
      <p className="mt-4 text-sm  lg:text-lg text-gray-400">
        Throughout our years in business, we have solidified a simple process
        that promises up front prices and makes it easy to know what to expect.
      </p>
    </div>
  )
}
